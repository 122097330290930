<!-- @format -->

<section class="box">
	<div *ngIf="isDraftRecovered" class="header bg-color-log-print_cream rounded-md bordered px-7 py-4 mb-3">
		<div class="d-flex ai-center bg-color-log-print_cream rounded-sm">
			<app-svg-icon
				class="d-block mr-3"
				appIcon="attention-round-yellow"
				appWidth="40"
				appHeight="40"
				appViewBox="0 0 40 40"
			></app-svg-icon>
			Обнаружен незаконченный черновик. Сохраните или отмените изменения.
		</div>
	</div>

	<div *ngIf="showTips" class="bg-color-neutral_white rounded-md bordered p-7 mb-3">
		<h1 class="mb-2">Загрузка произведения</h1>
		<div class="text-regular bg-color-neutral_dark-1 line-height-3 h5 mb-3">
			Вы можете загрузить рукопись, синопсис, графические и текстовые файлы с комментариями
			и дополнительной информацией.
			Чем больше полезной информации для анализа вашей заявки вы нам отправите,
			тем быстрее мы сможем вынести решение.
		</div>
		<div class="d-flex ai-center bg-color-log-print_yellow-25 rounded-sm p-3 mt-3">
			<app-svg-icon
				class="d-block mr-3"
				appIcon="attention-round-yellow"
				appWidth="40"
				appHeight="40"
				appViewBox="0 0 40 40"
			></app-svg-icon>
			<div class="h6 text-color-log-print_text line-height-3">
				Главное правило, которое мы просим соблюдать: <strong>1 книга = 1 заявка</strong>.
			</div>
		</div>
    <div class="mt-3 p-1 text-color-neutral_gray-3 h7 text-light">
      * Для черновиков доступны операции редактирования и удаления.
    </div>
	</div>

	<div class="bg-color-neutral_white rounded-md bordered p-7 mb-3">
		<h1 class="mb-2">Файлы произведения</h1>
		<div [hidden]="!showTips" class="h5 rounded-md bg-color-neutral_light-gray-2 line-height-3 p-3 mb-3">
			Просим не загружать на данном этапе финальные макеты изданий,
			возможность передать нам такие материалы появится на стадии сбора материалов,
			в случае дальнейшего хода по вашей заявке.
		</div>
		<form [formGroup]="uploadForm">
			<fieldset [disabled]="isBusy">
				<ngx-file-drop
			    	(onFileDrop)="NgxFileDropped($event)"
					[disabled]="isBusy"
					[multiple]="true"
					[accept]="mimeTypes.join(',')"
				>
					<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
						<div class="d-flex jc-center fd-column ai-center cursor-p min-h-[6em] pt-2"
							(click)="openFileSelector()"
						>
							<app-loader class="d-inline-block" [appIsLoading]="isBusy">
								<img
									class="d-block"
									src="/assets/images/welcome/upload-file.svg"
									alt="Загрузите записи вашей книги"
									slot="content"
								/>
							</app-loader>
							<div class="mt-1 text-color-neutral_gray-2 h7 text-nowrap p-1"
								 [hidden]="isBusy"
							>
								.pdf, .docx, .txt,
								.jpg (.jpeg), .png, .svg, .tiff, .mp3 - до 25 MБ, до 20 файлов
							</div>
							<div class="mt-2 p-1 flex flex-col items-center h6"
								 [hidden]="isBusy"
							>
								<span class="text-color-log-print_main-violet text-d-underline hover:opacity-75">
									Выберите элементы для загрузки</span>
                или перетащите (drag-n-drop) файлы в эту область
							</div>
						</div>
						<ul class="upload-list queued"
							[hidden]="!isBusy"
						>
							<li class="upload-item"
								*ngFor="let item of files; let i=index"
							>
								Загружается "{{ item.relativePath }}"...
							</li>
						</ul>
					</ng-template>
				</ngx-file-drop>
				<ul class="upload-list image p-0 my-1" *ngIf="uploadFormList.images.length">
					<!-- prettier-ignore -->
					<li
						appCardBookUploaded
						class="d-block"
						[ngClass]="{
							'span-3': !!uploadFormList.images[i - 1] || !!uploadFormList.images[i + 1],
							'span-2': !!uploadFormList.images[i - 1] && !!uploadFormList.images[i + 1] || (uploadFormList.images.length > 2 && (!uploadFormList.images[i - 1] || !uploadFormList.images[i + 1]))
						}"
						*ngFor="let formGroup of uploadFormList.images; let i = index"
						[appUpload]="formGroup.value"
						(remove)="onFileRemove(formGroup)"
					></li>
				</ul>
				<ul class="upload-list file p-0 my-1" *ngIf="uploadFormList.files.length">
					<li
						appCardBookUploaded
						class="d-block"
						*ngFor="let formGroup of uploadFormList.files"
						[appUpload]="formGroup.value"
						(remove)="onFileRemove(formGroup)"
					></li>
				</ul>
				<fieldset
					class="checkbox my-2"
					*ngIf="!!uploadFormList.images.length || !!uploadFormList.files.length"
				>
					<input
						id="terms"
						type="checkbox"
						data-cy="agreement"
						[checked]="uploadTerms"
						(change)="uploadTerms = !uploadTerms"
					/>
					<label for="terms" class="text-color-log-print_text">
						<span>Я подтверждаю, что обладаю авторскими правами на произведение и файлы</span>
					</label>
				</fieldset>
			</fieldset>
		</form>
	</div>
	<div class="bg-color-neutral_white rounded-md bordered p-7 mb-5">
		<h1 class="mb-2">Мета-данные</h1>
		<div [hidden]="!showTips" class="h5 rounded-md bg-color-neutral_light-gray-2 line-height-3 p-3 mb-3">
			В обязательном порядке необходимо заполнить название и аннотацию к книге, указать возрастной рейтинг и жанры.
			Текст аннотации должен быть лаконичен и четок.
		</div>
		<form [formGroup]="metaForm" class="w-full">
			<fieldset [disabled]="isBusy">
				<fieldset class="input d-flex fd-row jc-between ai-center mb-1">
					<label class="d-block h5 text-color-log-print_dark-gray" for="name">
						Название на обложке:
					</label>
					<input id="name" formControlName="name" placeholder="Пример: Золотой теленок" />
				</fieldset>
				<fieldset class="input d-flex fd-row jc-between ai-center mb-1">
					<label class="d-block h5 text-color-log-print_dark-gray" for="pseudonym">
						Авторы на обложке:
					</label>
					<input
						id="pseudonym"
						formControlName="pseudonym"
						placeholder="Пример: Илья Ильф, Евгений Петров"
					/>
				</fieldset>
				<fieldset class="input d-flex fd-row jc-between ai-center mb-1">
					<label class="d-block h5 text-color-log-print_dark-gray">
						Основной жанр:
					</label>
					<app-dropdown-input
						class="w-full"
						[appClassList]="['md']"
						[appData]="metaFormDropdownGenre"
						[appElectionId]="metaFormDropdownGenreElectionId"
						[appPlaceholder]="'Выберите жанр'"
						[appRequired]="true"
						(selected)="onSetGenre($event)"
						data-cy="genre"
					></app-dropdown-input>
				</fieldset>
				<fieldset class="input d-flex fd-row jc-between ai-stretch mb-1">
					<label class="d-block h5 text-color-log-print_dark-gray xs:leading-10">
						Поджанры:
					</label>
					<div class="d-flex fd-column w-full">
						<ul
							class="d-flex ai-start jc-start fw-wrap p-0"
							*ngIf="!!metaFormDropdownSubGenreSelected.length"
						>
							<li
								class="sub-genre-tag p-1 mr-1 my-h"
								*ngFor="let genre of metaFormDropdownSubGenreSelected"
							>
								<span class="d-block text-regular h7 mr-1">{{ genre.label }}</span>
								<app-svg-icon
									class="d-block cursor-p"
									*ngIf="{value: false} as hover"
									(touchstart)="hover.value = !hover.value"
									(mouseenter)="hover.value = true"
									(mouseleave)="hover.value = false"
									[appIcon]="hover.value ? 'cross--red' : 'cross--dark'"
									[appWidth]="14"
									[appHeight]="14"
									[appViewBox]="'0 0 16 16'"
									(click)="onRemoveSubGenre(genre)"
								></app-svg-icon>
							</li>
						</ul>
						<!-- prettier-ignore -->
						<app-dropdown-input
							class="w-full"
							[appClassList]="['md']"
							[appPlaceholder]="!!metaFormDropdownSubGenreSelected.length ? 'Выберите жанры' : 'Выберите дополнительные жанры'"
							[appRequired]="false"
							(selected)="onSetSubGenre($event)"
							(initialized)="onSubGenreDropdownInitialized($event)"
							[appData]="metaFormDropdownSubGenre"
							data-cy="sub-genre"
						></app-dropdown-input>
					</div>
				</fieldset>
				<fieldset class="d-flex fd-row jc-between ai-start mb-1">
					<label class="d-block h5 text-color-log-print_dark-gray" for="annotation">
						Аннотация:
					</label>
					<div class="textarea w-full">
						<textarea id="annotation" formControlName="annotation" rows="12" style="resize: vertical;"></textarea>
							<div class="d-flex jc-between ai-center">
								<span class="hint d-block text-color-neutral_gray-1 pl-1">
									от {{ metaFormAnnotationMinLength }} символов
								</span>
								<!-- prettier-ignore -->
								<span
									class="d-flex jc-end mt-1"
									[ngClass]="
										metaForm.dirty &&
										metaForm.get('annotation')?.errors &&
										metaForm.get('annotation')?.touched
										 	? 'text-color-log-print_main-red'
										 	: 'text-color-neutral_gray-1'
									"
								>
									{{ metaForm.get("annotation")?.value?.length }}/{{ metaFormAnnotationMinLength }}-{{ metaFormAnnotationMaxLength }}
								</span>
							</div>
						</div>
				</fieldset>
				<fieldset
					class="input d-flex fd-row jc-between ai-center mb-3"
					[class.mb-5]="!metaFormAgeRatingsActive"
				>
					<label class="d-block h5 text-color-log-print_dark-gray">
						Возрастной рейтинг:
					</label>
					<div
						class="d-flex jc-start w-full"
						*ngIf="metaForm.get('ageRating') as ageRating"
					>
						<fieldset
							class="checkbox mr-1"
							*ngFor="let rating of metaFormAgeRatings; let i = index"
						>
							<input
								[id]="'ageRating-' + i"
								type="radio"
								formControlName="ageRating"
								[value]="rating.id"
								hidden
							/>
							<label
								[for]="'ageRating-' + i"
								class="age-rating"
								[attr.data-cy]="'ageRating-' + i"
							>
								<span
									class="d-flex ai-center jc-center rounded-sm h4"
									[ngClass]="{
										'bg-color-neutral_gray-3 text-color-neutral_white':
											rating.id === ageRating.value
									}"
								>
									{{ rating.title }}
								</span>
							</label>
						</fieldset>
					</div>
				</fieldset>
				<div class="age-rating-current text-color-neutral_gray-2 h7 line-height-2 mb-5"
					*ngIf="metaFormAgeRatingsActive"
				>
					{{ metaFormAgeRatingsActive.description }}
				</div>
				<div class="form-control d-flex fd-row jc-between ai-center mb-1">
					<!-- prettier-ignore -->
					<button
						[disabled]="(!uploadFormList.files.length && !uploadFormList.images.length) || !uploadTerms || uploadForm.invalid || metaForm.invalid"
						(click)="onSubmitForm()"
						class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-6"
						data-cy="createBook"
					>
						<span [hidden]="isEdit">Добавить</span>
						<span [hidden]="!isEdit">Сохранить</span>
					</button>
					<small *ngIf="(!uploadFormList.files.length && !uploadFormList.images.length) || !uploadTerms || uploadForm.invalid || metaForm.invalid" class="m-0 ml-1 as-center text-color-log-print_main-red">
						* кнопка неактивна, т.к. одно или несколько полей не заполнены или содержат недопустимые значения
					</small>
				</div>
				<div class="mt-5 text-color-log-print_main-violet cursor-p"
					 (click)="onCancelForm()">
					Вернуться без внесения изменений
				</div>
			</fieldset>
		</form>
	</div>
</section>
