/** @format */

import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  forkJoin,
  from,
  Observable,
  Subscription,
} from 'rxjs';
import {
  first,
  skip,
  skipWhile,
  switchMap,
  tap
} from 'rxjs/operators';
import {
  Book,
  BooksService,
  Dropdown,
  FileService,
  HelperService,
  PlatformService,
  Reference,
  ReferenceAgeRating,
  ReferenceService,
  SnackbarService
} from '../../../../core';
import {
  Wizard,
  StepResume,
  StepUploadForm,
  UploadForm,
  UploadFormControl,
  EditForm,
  WizardService
} from '../../../core';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry
} from 'ngx-file-drop';

const isNull = (v?: any | null) => v !== undefined && v === null;

enum ComponentState {
  unknown,
  ready,
  busy,
}

@Component({
  selector: 'app-form-book-draft',
  templateUrl: './form-book-draft.component.html',
  styleUrls: ['./form-book-draft.component.scss']
})
export class FormBookDraftComponent implements OnInit, OnDestroy, AfterViewInit {
  // @ViewChild('fileInput') fileInput: any;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  State = ComponentState;
  state: ComponentState = ComponentState.unknown;
  isBusy = true;
  showTips = false;
  isEdit = true;
  isDraftRecovered = false;

  mimeTypes: string[] = [];

  uploadForm: FormGroup;
  uploadForm$?: Subscription;
  uploadTerms = false;
  uploadFormList: any = {
    images: [],
    files: []
  };

  metaForm: FormGroup;
  metaForm$?: Subscription;
  metaFormAnnotationMinLength = 20;
  metaFormAnnotationMaxLength = 600;

  metaFormAgeRatings: ReferenceAgeRating[] = [];
  metaFormAgeRatingsActive?: ReferenceAgeRating;

  metaFormDropdownGenre: Dropdown[] = [];
  metaFormDropdownGenreElectionId?: number;

  metaFormDropdownSubGenre: Dropdown[] = [];
  metaFormDropdownSubGenreSelected: Dropdown[] = [];
  metaFormDropdownSubGenreInitialized!: { [key: string]: any };

  wizardSubscription$?: Subscription;
  referenceSubscription$?: Subscription;

  public files: NgxFileDropEntry[] = [];

  constructor(
    private platformService: PlatformService,
    private snackbarService: SnackbarService,
    private fileService: FileService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private referenceService: ReferenceService,
    private wizardService: WizardService,
    private booksService: BooksService,
  ) {
    this.uploadForm = new FormGroup<UploadForm>({});

    this.metaForm = this.formBuilder.group<EditForm>({
      id: this.formBuilder.control(null),
      status: this.formBuilder.control(0),
      name: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
      pseudonym: this.formBuilder.control('', [Validators.minLength(3)]),
      genre: this.formBuilder.control(null, [Validators.required]),
      annotation: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(this.metaFormAnnotationMinLength),
        Validators.maxLength(this.metaFormAnnotationMaxLength)
      ]),
      ageRating: this.formBuilder.control(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.uploadForm$ = this.uploadForm.valueChanges.pipe(skip(1)).subscribe({
      next: () => {
        this.submit.emit({
          formValue: this.uploadForm.value,
          formName: 'uploadForm'
        });
      },
      error: (error: any) => console.error(error)
    });

    // prettier-ignore
    this.metaForm$ = this.metaForm.get('ageRating')?.valueChanges.subscribe({
      next: (id: number) => {
        this.metaFormAgeRatingsActive = this.metaFormAgeRatings.find((ageRating: ReferenceAgeRating) => {
          return ageRating.id === id;
        });
      },
      error: (error: any) => console.error(error)
    });

    this.referenceSubscription$ = this.referenceService.getReference().pipe(
      switchMap(() => this.referenceService.referenceSubject.pipe(skipWhile(isNull)))
    ).subscribe({
      next: (reference: Reference | null) => {
        /**
         * Set genre dropdown
         */
        this.metaFormDropdownGenre = reference!.genres.map((value, key) => ({
          id: key,
          label: value.title,
          value: value.id,
          dropdown: () => value.title
        }));

        /**
         * Set sub genre dropdown
         */
        this.metaFormDropdownSubGenre = this.metaFormDropdownGenre;

        /**
         * Set age ratings
         */
        this.metaFormAgeRatings = reference!.age_ratings;

        this.ngOnAfterInit();
      },
      error: (error: any) => console.error(error)
    });
  }

  protected ngOnAfterInit(): void {
    /**
     * Apply draft on form
     */
    this.wizardSubscription$ = this.wizardService.wizard$
      .pipe(
        first(),
        tap(() => (this.mimeTypes = this.wizardService.mimeTypes))
      )
      .subscribe({
        next: (wizard: Wizard) => {
          this.isDraftRecovered = 'recover' in wizard;
          this.isEdit = 'index' in wizard;
          this.showTips = !(this.isDraftRecovered || this.isEdit);

          if ('editForm' in wizard) {
            this.metaForm.patchValue(wizard.editForm);

            /**
             * Check if saved values of genre still exists in database
             */
            const isGenreActual = this.metaFormDropdownGenre.find((genre: Dropdown) => {
              return genre.value === wizard.editForm.genre;
            });

            if (!isGenreActual) {
              this.metaForm.get('genre')?.reset();
            } else {
              this.metaFormDropdownGenreElectionId = isGenreActual.id;
            }

            /**
             * Check if saved values of sub-genre still exists in database
             */
            wizard.editForm.sub_genres?.forEach((value: number) => {
              const isSubGenreActual = this.metaFormDropdownSubGenre.find((genre: Dropdown) => {
                return genre.value === value;
              });

              if (!!isSubGenreActual) {
                this.onSetSubGenre(isSubGenreActual);
              } else {
                // prettier-ignore
                wizard.editForm.sub_genres = wizard.editForm.sub_genres?.filter((genreValue: number) => {
                  return genreValue !== value;
                });
              }
            });

            /**
             * Check if saved values of age rating still exists in database
             */
            const isAgeRatingActual = this.metaFormAgeRatings.find((ageRating: any) => {
              return ageRating.id === wizard.editForm.ageRating;
            });

            if (!isAgeRatingActual) {
              this.metaForm.get('ageRating')?.reset();
            }
          }

          if ('uploadForm' in wizard) {
            Object.keys(wizard.uploadForm).forEach((key: string) => {
              // @ts-ignore
              const value: any = wizard.uploadForm[key];

              this.uploadForm.addControl(
                key,
                this.formBuilder.group<UploadFormControl>({
                  file: this.formBuilder.control(''),
                  fileName: this.formBuilder.control(value.fileName, [Validators.required]),
                  fileExtension: this.formBuilder.control(value.fileExtension, [
                    Validators.required
                  ]),
                  fileUrl: this.formBuilder.control(value.fileUrl, [Validators.required]),
                  fileId: this.formBuilder.control(value.fileId, [Validators.required]),
                  fileTerms: this.formBuilder.control(true, [Validators.requiredTrue])
                })
              );
            });

            this.onFileUpdate();

            this.uploadTerms = true;
          }

          // this.isEdit = 'editForm' in wizard && 'uploadForm' in wizard;
          this.isBusy = false;
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [
      this.uploadForm$,
      this.metaForm$,
      this.referenceSubscription$,
      this.wizardSubscription$
    ].forEach($ => $?.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.onStateChange(ComponentState.ready, true);
  }

  onStateChange(newState: ComponentState, withScroll?: boolean): void {
    this.state = newState;
    if (withScroll) this.platformService.getWindow().scroll({top: 0, left: 0, behavior: 'smooth'});
  }

  NgxFileDropped(files: NgxFileDropEntry[]) {
    this.isBusy = true;
    this.files = files.filter((droppedFile: NgxFileDropEntry) => droppedFile.fileEntry.isFile);
    const fileDropEntries = this.files?.map((droppedFile: NgxFileDropEntry) => {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      return from(new Promise((resolve) => fileEntry.file(resolve)));
    })
    forkJoin(fileDropEntries)
      .subscribe({
        next: (files) => this.onFileChange(files),
        complete: () => {
          const self = this;
          setTimeout(() => {
            self.files = [];
            self.isBusy = false;
          }, 600);
        },
        error: (error: any) => console.error(error)
      })
  }

  onFileChange(eventFileList: FileList | any[]): void {
    const fileList: File[] = Array.from(eventFileList);
    if (!fileList.length) return;

    const maxSize = 25;
    const formData: FormData = new FormData();
    let formDataLength = 0;
    for (const file of fileList) {
      const isValidMime = this.mimeTypes.includes(file.type);
      const isValidSize = file.size <= 1024 * 1024 * maxSize;
      if (!isValidMime || !isValidSize) {
        const errors: string[] = [];
        if (!isValidMime) {
          errors.push(`неподдерживаемый формат (${file.type})`);
        }
        if (!isValidSize) {
          errors.push(`превышен допустимый размер (${maxSize} МБ)`);
        }
        this.snackbarService.error(`Файл "${file.name}":\n- ${errors.join('\n- ')}`, 15000);
        continue;
      }
      formData.append('files[]', file);
      formData.append('confirm_copyright', '1');  // fixme
      if (++formDataLength > 19) break;
    }
    if (!formDataLength) {
      return;
    }

    this.fileService.postUpload(formData).subscribe({
      next: (uploadList: any) => {
        uploadList.forEach((upload: any) => {
          /** Push new files into uploadForm */
          if (upload.status === 'success') {
            const formGroup = this.formBuilder.group<UploadFormControl>({
              file: this.formBuilder.control(''),
              fileName: this.formBuilder.control(upload.file.client_name, [
                Validators.required
              ]),
              fileExtension: this.formBuilder.control(upload.file.extension, [
                Validators.required
              ]),
              fileUrl: this.formBuilder.control(upload.file.url, [Validators.required]),
              fileId: this.formBuilder.control(upload.file.id, [Validators.required]),
              fileTerms: this.formBuilder.control(true, [Validators.requiredTrue])
            });
            this.uploadForm.addControl(upload.file.id, formGroup);
            this.wizardService.onSetWizard('uploadForm', this.uploadForm.value);

            this.uploadTerms = false;
          }

          /** Error handler */
          if (upload.status === 'error') {
            console.log('error file', upload);
          }
        });

        this.onFileUpdate();

        // this.fileInput.nativeElement.value = '';
      },
      error: () => {
        // this.fileInput.nativeElement.value = '';
      }
    });
  }

  onFileRemove(formGroup: any): void {
    this.wizardService.removeUpload(formGroup.value.fileId).subscribe({
      next: (wizard: Wizard) => {
        this.uploadForm.removeControl(formGroup.value.fileId);

        this.onFileUpdate();

        /** If delete completely all books then go to previous state */
        // if (!Object.keys(this.editForm.controls).length && !Object.keys(this.uploadForm.controls).length) {
        // if (!Object.keys(this.uploadForm.controls).length) {
        //   this.state = 'upload';
        // }
      },
      error: (error: any) => console.error(error)
    });
  }

  onFileUpdate(): void {
    const uploadFormControlList: any[] = Object.values(this.uploadForm.controls);
    const uploadExtensionList: { [key: string]: string[] } = {
      images: ['jpg', 'jpeg', 'png', 'svg', 'tiff'],
      files: ['pdf', 'doc', 'docx', 'odt', 'rtf', 'txt', 'mp3']
    };

    Object.keys(uploadExtensionList).forEach((key: string) => {
      // prettier-ignore
      this.uploadFormList[key] = uploadFormControlList.filter((abstractControl: AbstractControl) => {
        const fileExtension: AbstractControl | null = abstractControl.get('fileExtension');

        return uploadExtensionList[key].includes(fileExtension?.value);
      });
    });

    this.uploadTerms = false;
  }

  onSetGenre(dropdown: Dropdown): void {
    const genreControl: AbstractControl | null = this.metaForm.get('genre');

    const genrePrevious = (): void => {
      /**
       * Если жанр уже был выбран ранее, и меняем его на другой || Если сбросили выбранный жанр
       * Нужно вернуть ранее выбранный жанр в список поджанров
       */

        // prettier-ignore
      const genre: Dropdown | undefined = this.metaFormDropdownGenre.find((genreDropdown: Dropdown) => {
          return genreDropdown.value === genreControl?.value;
        });

      if (!!genre) {
        this.metaFormDropdownSubGenre.unshift(genre);
      }
    };

    if (!!dropdown) {
      /** Remove genre from sub genre && sub genre selected list */

      if (genreControl?.valid) {
        genrePrevious();
      }

      // prettier-ignore
      this.metaFormDropdownSubGenre = this.metaFormDropdownSubGenre.filter((subGenre: Dropdown) => subGenre.id !== dropdown.id);

      // prettier-ignore
      // eslint-disable-next-line max-len
      this.metaFormDropdownSubGenreSelected = this.metaFormDropdownSubGenreSelected.filter((subGenreSelected: Dropdown) => subGenreSelected.id !== dropdown.id);

      genreControl?.setValue(dropdown.value);
    } else {
      /** Rollback genre to sub genre list */

      genrePrevious();

      genreControl?.setValue(null);
    }
  }

  onSetSubGenre(dropdown: Dropdown): void {
    if (dropdown) {
      this.metaFormDropdownSubGenreSelected.push(dropdown);

      // prettier-ignore
      this.metaFormDropdownSubGenre = this.metaFormDropdownSubGenre.filter((dropdownGenre: Dropdown) => {
        return dropdownGenre.id !== dropdown.id;
      });
    }

    const resetTimeout: any = setTimeout(() => {
      this.metaFormDropdownSubGenreInitialized?.onReset();

      clearTimeout(resetTimeout);
    });
  }

  onRemoveSubGenre(dropdown: Dropdown): void {
    this.metaFormDropdownSubGenre.unshift(dropdown);

    // prettier-ignore
    this.metaFormDropdownSubGenreSelected = this.metaFormDropdownSubGenreSelected.filter((dropdownGenre: Dropdown) => {
      return dropdownGenre.id !== dropdown.id;
    });
  }

  onSubGenreDropdownInitialized($event: { [key: string]: any }): void {
    this.metaFormDropdownSubGenreInitialized = $event;
  }

  onSubmitForm(): void {
    // prettier-ignore
    // eslint-disable-next-line max-len
    const uploadFormIsValid: boolean = Object.values(this.uploadForm.controls).every((abstractControl: AbstractControl) => abstractControl.valid);
    const metaFormIsValid: boolean = this.helperService.getFormValidation(this.metaForm);

    if (uploadFormIsValid && metaFormIsValid && this.uploadTerms) {
      this.isBusy = true;

      const body = {
        title: this.metaForm.value.name,
        pseudonym: this.metaForm.value.pseudonym,
        annotation: this.metaForm.value.annotation,
        genre_id: this.metaForm.value.genre,
        sub_genres: this.metaFormDropdownSubGenreSelected.map((dropdown: Dropdown) => dropdown.value),
        age_rating_id: this.metaForm.value.ageRating,
        files: Object.keys(this.uploadForm.controls)
      };

      const emit = (): void => {
        this.submit.emit({
          formValue: {
            ...this.metaForm.value,
            // prettier-ignore
            sub_genres: body.sub_genres
          },
          formName: 'editForm'
        });
      };

      if (this.isEdit) {
        this.booksService.update(this.metaForm.get('id')?.value, body)
          .subscribe({
            next: () => emit(),
            error: (error: any) => {
              console.error(error);
              this.isBusy = false;
            }
          });
      } else {
        this.booksService.create(body)
          .pipe(tap((book: Book) => this.metaForm.get('id')?.setValue(book.id)))
          .subscribe({
            next: () => emit(),
            error: (error: any) => {
              console.error(error);
              this.isBusy = false;
            }
          });
      }
    }
  }

  addControlForFile(fileId: string, file: any): void {
    const formGroup = this.formBuilder.group<UploadFormControl>({
      file: this.formBuilder.control(file.file),
      fileName: this.formBuilder.control(file.fileName, [Validators.required]),
      fileExtension: this.formBuilder.control(file.fileExtension, [Validators.required]),
      fileUrl: this.formBuilder.control(file.fileUrl, [Validators.required]),
      fileId: this.formBuilder.control(file.fileId, [Validators.required]),
      fileTerms: this.formBuilder.control(file.fileTerms, [Validators.requiredTrue])
    });
    this.uploadForm.addControl(fileId, formGroup);
    this.onFileUpdate();
  }

  removeControlForFile(fileId: string): void {
    this.uploadForm.removeControl(fileId);
    this.onFileUpdate();
  }

  onCancelForm(): void {
    const wizard: Wizard = this.wizardService.wizard$.getValue();
    const resumeIndex: number | undefined = wizard.index;  // original draft index
    const stepOrig: StepResume = (resumeIndex !== undefined) ? wizard.resume[resumeIndex] : {} as StepResume;  // original draft values

    // если новые файлы были загружеы и не сохранены - их требуется удалить
    const needToUnset: string[] = Object.keys(this.uploadForm.controls);
    // прежние файлы необходимо сохранить
    const toKeep: string[] = Object.keys(stepOrig?.uploadForm ?? {} as StepUploadForm);
    const toUnset: string[] = needToUnset.filter((val) => toKeep.indexOf(val) === -1);
    toUnset.forEach((fileId: string) => console.error('Unset temporary resource', fileId));
    // const apiCalls: Observable<any>[] = toUnset.map((fileId: string) => {
    //   return this.fileService.deleteUpload(fileId);
    // });
    const apiCalls: Observable<any>[] = [];
    if (toUnset.length) apiCalls.push(this.fileService.bulkDeleteUploads(toUnset));  // Fix Issue 429 Too Many Requests
    forkJoin(apiCalls).subscribe({
      next: (messages: any[]) => {
        messages.forEach((message) => this.snackbarService.success(message.message));
      },
      complete: () => {
        // удаленные файлы из формы убираем
        toUnset.forEach((fileId: string) => {
          this.removeControlForFile(fileId);
        });
        // прежние файлы возвращаем
        toKeep.forEach((fileId: string) => {
          if (!this.uploadForm.get(fileId)) {
            // добавляем Control, если нет
            const file = stepOrig.uploadForm[fileId];
            this.addControlForFile(fileId, file);
          }
        });
        //this.editForm.patchValue(wizard.editForm);
        this.cancel.emit();
      },
      error: () => {
        this.snackbarService.info(
          'Вследствие внутренней ошибки не удалось выполнить действие.' +
          ' Обратитесь к менеджеру, пожалуйста.', 15000);
      }
    });
  }
}
