<!-- @format -->

<ng-container *ngIf="book && book.flag && messageMap && messageMap[book.flag] as message">
	<div class="box d-flex ai-start rounded-sm p-3">
		<app-svg-icon
			class="mr-3"
			[appIcon]="message.icon"
			[appWidth]="message['iconWidth']"
			[appHeight]="message['iconHeight']"
			[appViewBox]="'0 0 24 25'"
		></app-svg-icon>
		<div class="d-flex fd-column">
			<span class="text-bold h5 my-1">{{ message.title }}</span>
			<span *ngIf="book.title" class="h6 my-1">Выбрана книга <q class="text-semibold">{{ book.title }}</q></span>
			<p class="line-height-3 m-0">{{ message.text }}</p>
		</div>
	</div>
</ng-container>
