/** @format */

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Autocomplete, AutocompleteService, Dropdown } from '../../../../core';
import { fadeAnimation, rotateAnimation } from '../../../../app-animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { PlatformService } from '../../../../core';

interface InputForm {
  address: FormControl<string | null>;
}

@Component({
  selector: 'app-delivery-autocomplete',
  templateUrl: './delivery-autocomplete.component.html',
  styleUrls: ['./delivery-autocomplete.component.scss'],
  animations: [fadeAnimation, rotateAnimation]
})
export class DeliveryAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('inputElement') inputElement!: ElementRef;

  @Input()
  set appAddress(address: string | undefined) {
    if (address !== undefined && address !== null && address.length > 0) {
      this.inputForm.get('address')?.setValue(address);
    }
  }

  @Input()
  set appPlaceholder(placeholder: string) {
    this.placeholder = placeholder;
  }

  @Input()
  set appClassList(classList: string[]) {
    this.classList = classList;
  }

  @Output() selected = new EventEmitter<any>();
  @Output() hovered = new EventEmitter<any>();

  inputForm$: Subscription | undefined;

  isSubmitted = false;

  inputForm: FormGroup;
  data: Dropdown[] = [];

  placeholder: string | undefined;
  classList: string[] = [];

  toggleDropdown = false;
  toggleHovered = -1;

  constructor(
    private platformService: PlatformService,
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private autocompleteService: AutocompleteService
  ) {
    this.inputForm = this.formBuilder.group<InputForm>({
      address: this.formBuilder.control('', [Validators.required, Validators.minLength(3)])
    });
  }

  ngOnInit(): void {
    // prettier-ignore
    this.inputForm$ = this.inputForm.get('address')?.valueChanges
      .pipe(
        filter((address: string) => {
          return !!this.inputForm.get('address')?.valid && !this.data.find(d => d.label === address)
        }),
        debounceTime(750),
        distinctUntilChanged()
      )
      .subscribe({
        next: (address: string) => {
          this.inputForm.get('address')?.setErrors(null);
          this.isSubmitted = true;
          this.data = [];
          this.selected.emit('');
          this.autocompleteService.get('address', address).subscribe({
            next: (data: Autocomplete[] | undefined) => {
              if (data?.length) {
                this.data = data.map((item: Autocomplete, index) => {
                  return {
                    id: index,
                    label: item.value,
                    value: item.value,
                    dropdown: () => item.value
                  };
                });
                this.inputForm.get('address')?.setErrors({ notSelected: true });
              } else {
                this.inputForm.get('address')?.setErrors({ notFound: true });
              }

              this.isSubmitted = false;
              setTimeout(() => {
                this.inputElement.nativeElement.focus();
              }, 0);
            },
            error: () => (this.isSubmitted = false)
          });
        },
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [this.inputForm$].forEach($ => $?.unsubscribe());
  }

  onHover(li: Dropdown | undefined): void {
    this.inputForm.get('address')?.patchValue(li ? li.value : '');

    this.hovered.emit(li?.value);

    this.toggleHovered = li ? this.data.findIndex(d => d.label === li.label) : -1;
  }

  onSelect(li: Dropdown | undefined): void {
    this.inputForm.get('address')?.patchValue(li ? li.value : '');

    this.selected.emit(li?.value);

    this.toggleHovered = li ? this.data.findIndex(d => d.label === li.label) : -1;
  }

  onBlur(event?: Event): void {
    setTimeout(() => {
      this.toggleDropdown = false;
    }, 200);
  }

  onKeyup(event: KeyboardEvent): void {
    if (this.toggleDropdown && this.data.length > 0) {
      event.preventDefault();

      const inner: HTMLElement = this.el.nativeElement.querySelector('.dropdown-inner');
      // @ts-ignore
      const ul: HTMLElement = inner.querySelector('ul');
      // @ts-ignore
      const li: HTMLElement = ul.querySelector('li');

      const getScrollHeight = (n: number) => li.offsetHeight * (this.inputForm.valid ? n + 1 : n);

      switch (event.key) {
        case 'ArrowDown':
          if (this.toggleHovered < this.data.length - 1) {
            this.toggleHovered++;

            const scrollHeight = getScrollHeight(this.toggleHovered + 1);

            if (scrollHeight > ul.offsetHeight) {
              const scrollHeightDifference = scrollHeight - ul.offsetHeight;

              if (scrollHeightDifference > ul.scrollTop) {
                ul.scrollTop = scrollHeightDifference;
              }
            }
          }

          this.onHover(this.data[this.toggleHovered]);
          break;
        case 'ArrowUp':
          if (this.toggleHovered) {
            this.toggleHovered--;

            const scrollHeight = getScrollHeight(this.toggleHovered);

            if (scrollHeight < ul.scrollTop) {
              ul.scrollTop = scrollHeight;
            }
          }

          this.onHover(this.data[this.toggleHovered]);
          break;
        case 'Enter':
          this.onSelect(this.data[this.toggleHovered]);
          break;
      }
    }
  }
}
